import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import * as S from "./styles"
import { FormattedMessage } from "gatsby-plugin-react-intl"

interface FooterProps {
  light?: boolean
}

const Footer: React.FC<FooterProps> = ({ light = false }) => {
  const images = useStaticQuery(graphql`
    query {
      lightGithub: file(relativePath: { eq: "github-logo-light.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darkGithub: file(relativePath: { eq: "github-logo-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lightTwitter: file(relativePath: { eq: "twitter-logo-light.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darkTwitter: file(relativePath: { eq: "twitter-logo-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lightYouTube: file(relativePath: { eq: "youtube-logo-light.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darkYouTube: file(relativePath: { eq: "youtube-logo-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lightDocs: file(relativePath: { eq: "docs-light.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darkDocs: file(relativePath: { eq: "docs-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Footer>
      {/* <S.InternalLink to="/docs" light={light}>
        <S.Icon>
          <Img
            fluid={
              light
                ? images.lightDocs.childImageSharp.fluid
                : images.darkDocs.childImageSharp.fluid
            }
            alt="Documentation"
          />
        </S.Icon>{" "}
        <FormattedMessage defaultMessage="Documentation" description="footer" />
      </S.InternalLink> */}
      <S.ExternalLink href="https://github.com/learntray" light={light}>
        <S.Icon>
          <Img
            fluid={
              light
                ? images.lightGithub.childImageSharp.fluid
                : images.darkGithub.childImageSharp.fluid
            }
            alt="GitHub account"
          />
        </S.Icon>{" "}
        <FormattedMessage defaultMessage="GitHub" description="footer" />
      </S.ExternalLink>
      <S.ExternalLink href="https://twitter.com/learn_tray" light={light}>
        <S.Icon>
          <Img
            fluid={
              light
                ? images.lightTwitter.childImageSharp.fluid
                : images.darkTwitter.childImageSharp.fluid
            }
            alt="Twitter account"
          />
        </S.Icon>{" "}
        <FormattedMessage defaultMessage="Twitter" description="footer" />
      </S.ExternalLink>
      <S.ExternalLink
        href="https://www.youtube.com/channel/UChfbaXmvStGlMNan8rUqKSA"
        light={light}
      >
        <S.Icon>
          <Img
            fluid={
              light
                ? images.lightYouTube.childImageSharp.fluid
                : images.darkYouTube.childImageSharp.fluid
            }
            alt="YouTube account"
          />
        </S.Icon>{" "}
        <FormattedMessage defaultMessage="YouTube" description="footer" />
      </S.ExternalLink>
    </S.Footer>
  )
}

export { Footer }
