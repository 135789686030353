import React from "react"
import { Link } from "gatsby-plugin-react-intl"
import styled from "styled-components"

export const Footer = styled.div`
  opacity: 50%;
  text-align: center;
  padding: 0 20px 30px 20px;
`

export const InternalLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: ${props => (props.light ? "#fff" : "#000")};
  font-weight: bold;
  font-size: 0.9em;
  margin: 10px;
`

export const ExternalLink = styled.a<{ light: boolean }>`
  text-decoration: none;
  color: ${props => (props.light ? "#fff" : "#000")};
  font-weight: bold;
  font-size: 0.9em;
  margin: 10px;
`

export const Icon = styled.div`
  width: 24px;
  height: 22px;
  padding: 0 2px;
  vertical-align: middle;
  display: inline-block;
`
